import React from 'react'
import './beneficios.sass'
import { beneficios_plataforma, beneficios_plataforma_vertical } from '../../constants/images'

export default function Beneficios() {
    return (
        <div className="beneficios container">
            <h2 className="beneficios__title">
                Gestiona tus ventas y/o rentas en un solo sitio
            </h2>
            <p className="beneficios__text">
                <strong>INMMO</strong> es un <strong>CRM</strong> pensado y creada <i className="beneficios__text--frag">exclusivamente</i> para el sector inmobiliario, con la finalidad de asegurar una óptima interacción entre usuarios y sistema.
                <br />
                Permitiendo un mayor control de tu trabajo y/o equipo.
            </p>            
                <picture>
                    <source media="(min-width: 768px)" srcSet={beneficios_plataforma} />
                    <img width="100%" src={beneficios_plataforma_vertical} alt="Beneficios de la plataforma"  />
                </picture>
                {/* <img className="beneficios__img" src={beneficios_plataforma_vertical} alt="Flujo de la plataforma"/> */}

            <hr className="separator" />
        </div>

    )
}
