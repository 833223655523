import React, { useState } from 'react'

// Style
import './contactanos.sass'

// Libraries
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faEnvelope, faMobileAlt, faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons'

// Services
import { _post } from '../../services/Http'
import Swal from 'sweetalert2'

export default function Contactanos() {
    
    const { register, handleSubmit, errors  } = useForm()
    const [sending, setSending] = useState(false)

    const enviarContacto = async (data) => {
        
        setSending(true);

        try {

            let req = await _post('/contact', data)

            console.log(req);

            setSending(false);
            Swal.fire('Exito', 'Se envio la información exitosamente, pronto nuestro equipo se contactara con usted pronto.', 'success')

        } catch(e) {
            
            console.log(e);
            setSending(false);
            Swal.fire('Error', 'Hubo un problema al momento de enviar su información, intente esta operación más tarde', 'error')
        }
    } 

    return (
        <div className="contactanos container">
            <h2 className="contactanos__title text-center">¡Contáctanos para solicitar un plan a tu medida!</h2>
            <p className="contactanos__subtitle text-muted">
                <i>
                    Envíanos un correo con tus datos y el equipo de servicio al cliente se pondrá en contacto contigo.
                </i> 
            </p>

            <div className="row mt-5">
                <div className="col-md-6 mb-5">
                    
                    <form onSubmit={handleSubmit(enviarContacto)}>
                        <div className="form-row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="nombre">Nombre completo</label>
                                    <input ref={register({required: true})} id="nombre" name="nombre" type="text" className="form-control"/>
                                    {errors.nombre && <span className="text-danger">El nombre completo es obligatorio</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="correo">Correo electrónico</label>
                                    <input 
                                    ref={register({
                                        required: "El correo electrónico es obligatorio",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Ingrese un correo electrónico valido"
                                        }
                                        })}
                                    id="correo" name="correo" type="email" className="form-control"/>
                                    {errors.correo && <span className="text-danger">{ errors.correo.message }</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <input ref={register({required: true})} id="telefono" name="telefono" type="text" className="form-control"/>
                                    {errors.telefono && <span className="text-danger">El teléfono completo es obligatorio</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="asunto">Asunto</label>
                                    <input ref={register({required: true})} id="asunto" name="asunto" type="text" className="form-control"/>
                                    {errors.asunto && <span className="text-danger">El asunto completo es obligatorio</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="mensaje">Mensaje</label>
                                    <textarea className="form-control" id="mensaje" name="mensaje" cols="30" rows="8"
                                        ref={register({required: "El mensaje es obligatorio", minLength:{value: 30, message:'El mensaje debe contener al menos 30 caracteres'}  })}
                                    ></textarea>
                                    {errors.mensaje && <span className="text-danger">{errors.mensaje.message}</span>}
                                </div>
                            </div>
                            
                            <div className="col-md-12">
                                <button disabled={ sending }  className="btn contactanos__boton-enviar">
                                    {
                                        sending ?
                                        <FontAwesomeIcon icon={faSpinner} pulse /> 
                                        :
                                        <FontAwesomeIcon icon={faPaperPlane} /> 
                                    }
                                    &nbsp; Enviar mensaje
                                </button>
                                {/* {
                                    sending ?
                                    :
                                    <button className="btn contactanos__boton-enviar">

                                        &nbsp; Enviar mensaje
                                    </button>
                                } */}
                            </div>

                        </div>
                    </form>
                </div>

                <div className="offset-md-2 col-md-4 contactanos__info-contacto">
                    <h5 className="font-weight-light mb-4 contactanos__subsection-title">Información del contacto</h5>
                    <p>
                        <FontAwesomeIcon className="contactanos__icon" icon={faMapMarkerAlt} /> Hermosillo, Sonora, México
                    </p>
                    <p>
                        <FontAwesomeIcon className="contactanos__icon" icon={faEnvelope} /> contacto@inmmo.com.mx
                    </p>
                    <p>
                        <FontAwesomeIcon className="contactanos__icon" icon={faMobileAlt}  /> +52 (662) 297 7542
                    </p>
                    <p className="mt-4 contactanos__horario">
                        Lunes – Sábado: 8:00 - 16:00
                    </p>
                </div>
            </div>
        </div>
    )
}
