import React from 'react'
import './planes.sass'

import { Card, ListGroup, Row, Col } from 'react-bootstrap'

const planes = [
    {
        nombre: 'Único',
        precio: '250',
        caracteristicas: [
            'Pagando 1 mes',
            'Propiedades ilimitadas'
        ]
    },
    {
        nombre: 'Equipo',
        precio: '210',
        caracteristicas: [
            'Pagando 3 meses',
            'Propiedades ilimitadas',
            'Página Web'
        ]
    },
    {
        nombre: 'Pro',
        precio: '170',
        caracteristicas: [
            'Pagando 6 meses',
            'Propiedades ilimitadas',
            'Página Web'
        ]
    },
    {
        nombre: 'Master',
        precio: '150',
        caracteristicas: [
            'Pagando 12 meses',
            'Propiedades ilimitadas',
            'Página web'
        ]
    }
]

const renderPlanes = planes.map( (plan, index) => 
    <Col key={index} md="6" lg="4" xl="3" className="d-flex align-self-stretch">
        <Card className="planes__card mx-auto" style={{ width: '18rem' }}>
            <Card.Body>
                <Card.Title className="planes__card-nombre text-uppercase text-center">{plan.nombre}</Card.Title>
                <Card.Text className="planes__card-precio">
                    <span className="planes__card-signo">
                        $    
                    </span>
                    <span className="planes__card-dinero">{plan.precio}</span>/mes
                </Card.Text>

                <ListGroup className="text-center" variant="flush">

                    {   
                        plan.caracteristicas.map( (caracteristica, index) =>    

                            <ListGroup.Item key={index} className="planes__card-caracteristicas">
                                {caracteristica}
                            </ListGroup.Item>
                        ) 
                    }
                </ListGroup>
                
            </Card.Body>
        </Card>
    </Col>    
    
)


export default function Planes() {
    return (
        <div className="planes container">
            <h2 className="planes__title font-weight-light text-center text-uppercase ">
                Nuestros planes
            </h2>

            <div className="planes__subtitle">
                <i>
                    Sencillo, útil, adecuado para trabajar en cualquier lugar con información actualizada en tiempo real.
                </i>
            </div>

            <Row>
                {renderPlanes}
            </Row>
            
            <hr className="separator" />
        
        </div>
    )

}