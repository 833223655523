export async function _post(controller, data) {
    let response = await fetch(process.env.REACT_APP_API_URL + controller, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttprequest',
            'X-API-KEY': process.env.REACT_APP_API_KEY,
        },
    })

    let status = response.status;
    response = await response.json()
    return {
        status: status,
        message: response.message
    }
}
export async function _get(controller) {
    let response = await fetch(process.env.REACT_APP_API_URL + controller, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttprequest',
            'X-API-KEY': process.env.REACT_APP_API_KEY,
        },
    })
    let status = response.status
    response = await response.json()
    
    if(status === 404 || status === 401 || status === 400 || status === 500) {
        
        throw new Error({ status: status, message: response.message })
        
    }
    
    return response
    
}