import React from 'react'
import { pin_mapa, escoger_casa } from "../../constants/images";

import './acerca.sass'

export default function Acerca() {
    return (
        <div className="container mt-5 acerca">
            <h1 className="acerca__title">
                Realizar búsquedas de propiedades nunca fue tan <i>fácil.</i>
            </h1>

            <h2 className="acerca__subtitle">
                Nuestro Asistente se encargará de ello, así tu productividad y ventas <br/>
                <span className="acerca__subtitle-frag">
                    Aumentan
                </span>
            </h2>

            <div className="mt-5">
                <img className="acerca__img"  src={pin_mapa}  alt="Pin del mapa" ></img>
                <img className="acerca__img"  src={escoger_casa} alt = "Escoger casa" />
            </div>


            <hr className="separator"/>

        </div>
    )
}
