import React from 'react'
import { Navbar, Nav, Form} from "react-bootstrap";
import './main-hero.sass'
import main_hero from '../../img/main-hero-2.jpg'
import logo_inmmo from '../../img/logo_inmmo_blanco.png'

export default function MainHero() {
    return (
        <div>
            <div className="">
                <Navbar className="navigation navbar-dark" bg="transparent" expand="lg">
                    <Navbar.Brand />
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav m.">
                        <Nav className="ml-md-auto">
                            <Form inline>
                                <a href="https://app.inmmo.com.mx/login" className="navigation__acceder text-uppercase mr-4 btn btn-outline-light">
                                    Acceder
                                </a>
                            </Form>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>

            <div className="main-hero">
                <img className="main-hero__img" width="100%" src={main_hero} alt="Portada de Inmmo" />

                <div className="main-hero__content">
                    <div className="text-center mb-5">
                        <img className="main-hero__logo" src={logo_inmmo}  alt="Logotipo de INMMO"/>
                    </div>
                    <h1 className="main-hero__title">
                        El asistente virtual que trabaja por ti.
                    </h1>
                </div>
            </div>
        </div>
    )
}
