import React, { Component } from 'react'
import './footer.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faArrowCircleRight  } from '@fortawesome/free-solid-svg-icons'


export default class Footer extends Component {
    render() {
        return (
            <div className="mt-5 footer p-md-5 p-1">
                <footer className="container ">
                    <div className="row">
                        <div className="col-md-4">
                            <h3 className="footer__section-title">
                                Acerca de nosotros
                            </h3>

                            <p className="footer__section-text">
                            <strong>INMMO</strong> es un producto de <strong>Macte Nova Tech</strong>, somos una empresa dedicada al desarrollo de
                            software en apoyo a las micro empresas.
                            </p>
                        </div>

                        <div className="col-md-4">
                            <h3 className="footer__section-title">
                                Nuestros productos
                            </h3>

                            <div className="row">
                                <div className="col-4">
                                    <img className="footer__products-img" src="https://inteligente.inmmo.com.mx/assets/img/logo_mactenova.jpg" alt="Logo Macte Nova Tech"/>
                                </div>
                                <div className="col-8 footer__products-text">
                                    <h5 className="footer__products-title">Macte Nova Tech</h5>
                                    <p>
                                        Empresa dedicada al desarrollo de
                                        software en apoyo a las micro empresas.
                                        <br/><br/>
                                        
                                        
                                        <a target="_blank" rel="noopener noreferrer" href="https://mactenova.mx">
                                            <FontAwesomeIcon  icon={faArrowCircleRight} />  Ver Más
                                        </a>
                                    </p>
                                    
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-4">
                                    <img className="footer__products-img" src="https://inteligente.inmmo.com.mx/assets/img/logo_inmmo.jpg" alt="Logo Macte Nova Tech"/>
                                </div>
                                <div className="col-8 footer__products-text">
                                    <h5 className="footer__products-title">Portal Inteligente</h5>
                                    <p>
                                        Aplicación dedicada para los agentes
                                        inmobiliarios que deseen incrementar
                                        sus ventas por medio del networking
                                        automático
                                        <br/><br/>
                                        
                                        <a target="_blank" rel="noopener noreferrer" href="https://inteligente.inmmo.com.mx">
                                            <FontAwesomeIcon  icon={faArrowCircleRight} />  Ver Más
                                        </a>
                                    </p>
                                    
                                </div>
                            </div>

                        </div>

                        <div className="col-md-4">
                            <h3 className="footer__section-title">
                                Siguenos en nuestras redes
                            </h3>

                            <p className="footer__section-social">
                                <a href="https://www.facebook.com/inmmohmo" target="_blank" rel="noopener noreferrer" className="footer__social">
                                    <FontAwesomeIcon size="lg" icon={faFacebookF} />
                                </a>
                                <a href="https://www.instagram.com/mactenova/" target="_blank" rel="noopener noreferrer" className="footer__social">
                                    <FontAwesomeIcon size="lg" icon={faInstagram} />
                                </a>
                                <a href="https://www.linkedin.com/company/mactenovatech/" target="_blank" rel="noopener noreferrer" className="footer__social">
                                    <FontAwesomeIcon size="lg" icon={faLinkedin} />
                                </a>
                            </p>
                        </div>


                    </div>


                    <p className="footer__copyright text-center mt-5">
                        Copyright © All rights reserved | Powered by Macte Nova Tech
                    </p>
                    
                </footer>
            </div>
        )
    }
}
