import React from 'react';
import './App.sass';

import MainHero  from './components/main-hero'
import Acerca from './components/acerca';
import Beneficios from './components/beneficios';
import Planes from './components/planes';
import Contactanos from './components/contactanos';
import Footer from './components/footer';

function App() {
  return (
    <div>
      <MainHero />
      <Acerca />
      <Beneficios />
      <Planes/>
      <Contactanos/>
      <Footer />
    </div>
  );
}

export default App;
